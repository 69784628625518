import { navigate } from "@reach/router";
import { graphql } from "gatsby";
import { injectIntl, useIntl } from "gatsby-plugin-intl";
import { Contentful_Page, Contentful_PageBlocksItem } from "graphql-types";
import get from "lodash/get";
import React from "react";
import BlockLoader, { hasHeroBlock } from "../components/block-loader";
import { LocationProvider } from "../contexts/LocationContext";
import { ContentTypeColour, ThemeContext } from "../contexts/ThemeContext";
import Layout from "../layouts/layout";
import contentfulImageHelper from "../utils/contentful-image-helper";
import { filterDataByLocation } from "../utils/filters";

const PageEntryTemplate = ({ location, data }: { location: any; data: any }) => {
  let pageData: Contentful_Page = get(data, "contentful.page");
  const { blocksCollection } = get(data, "contentful.blocksLocation");

  /**
   * As page entries contain dynamic content blocks, we need to determine whether
   * the page's first block is a hero. This affects the style of the navigation bar.
   */
  const firstBlock: Contentful_PageBlocksItem | undefined = get(
    pageData,
    "blocksCollection.items.0"
  ) as Contentful_PageBlocksItem;
  const isPrimaryNavigationDark = pageData.isPrimaryNavigationDark ?? false;

  if (!pageData.blocksCollection) return null;

  const { locale } = useIntl();

  if (!filterDataByLocation(pageData, locale)) {
    navigate("/");
  }

  return (
    <ThemeContext.Provider value={ContentTypeColour.Default}>
      <LocationProvider>
        <Layout
          headSnippet={pageData.headSnippet}
          title={pageData.title}
          metaDescription={pageData.metaDescription}
          metaImageUrl={contentfulImageHelper(pageData.shareImage).getShareUrl()}
          hasHero={hasHeroBlock(firstBlock) ? true : !isPrimaryNavigationDark}
        >
          <main>
            <h1 className="sr-only">{pageData.title}</h1>
            <BlockLoader
              blocks={pageData.blocksCollection.items}
              blocksLocation={blocksCollection.items}
              hasSideNavigation={pageData.hasSideNavigation ?? false}
              pageId={pageData.sys.id}
            />
          </main>
        </Layout>
      </LocationProvider>
    </ThemeContext.Provider>
  );
};

export default injectIntl(PageEntryTemplate);

export const pageQuery = graphql`
  query PageById($id: String!, $preview: Boolean!) {
    contentful {
      page(id: $id, preview: $preview) {
        ...pageFields
      }
      blocksLocation: page(id: $id, preview: $preview) {
        ...pageBlocksLocation
      }
    }
  }
`;
